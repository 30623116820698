import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FormRegisterSimpleInlineComponent } from './form-register-simple-inline/form-register-simple-inline.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { FormRegisterCompanyComponent } from './form-register-company/form-register-company.component';
import {
  MatButtonModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, 
  MatDialogModule, MatFormFieldModule, MatIconModule,
  MatInputModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule, 
  MatSelectModule, MatSnackBarModule
} from '@angular/material';
import {MatSnackBar} from '@angular/material';
@NgModule({
  declarations: [
    FormRegisterSimpleInlineComponent,
    FormContactComponent,
    FormRegisterCompanyComponent
  ],
  imports: [
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule,
    MatButtonModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, 
    MatDialogModule, MatFormFieldModule, MatIconModule,
    MatInputModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule, 
    MatSelectModule, MatSnackBarModule],
  exports: [
    FormRegisterSimpleInlineComponent,
    FormContactComponent,
    FormRegisterCompanyComponent
  ]
})
export class ActionsModule {}
