import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { CorporateComponent } from './components/corporate/corporate.component';
import { Shell } from '@app/shell/services/shell.service';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  Shell.childRoutes([
    {
      path: '',
      component: CorporateComponent,
      data: { title: extract('About') }
    },
    {
      path: 'home',
      component: CorporateComponent,
      data: { title: extract('Home') }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateRoutingModule {}
