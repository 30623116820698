import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  constructor() {}

  getFaqs(): Observable<any> {
    return of([
      {
        question:
          'I am already using a Trace System, how do i upgrade to TraceWorks?',
        answer:
          'All you need to do is contact us to open an account. At that point you need to also invite the companies that you do business with to also join the network, as both the supplier and buyer of a traced transaction needs to be on the network. If the companies you do business with are already on the network, ask them for their account number so you can connect to them. We will charge a small fee for onboarding and setting up new accounts. '
      },
      {
        question:
          'My business has yet to use a trace system, how do I get started?',
        answer:
          'Using our trace system is easy. We will walk you through the software as part of our onboarding process. If you are a supplier, each traceable product on a customer’s PO will require a trace document.'
      },
      {
        question:
          'When does the Food Safety Modernization Act (FSMA) require I use a trace solution?',
        answer:
          'TraceWorks is intended to complement the implementation of the FDA Food Safety Modernization Act (FSMA) – Preventive Controls for Human Foods, Preventive Controls for Animal Food, and the Foreign Supplier Verification Programs. The FSMA requires a supply-chain program for certain raw materials and other ingredients. This program is designed to address hazards requiring a supply-chain-applied control.'
      },
      {
        question:
          'Why am I still doing all my data collection using traditional paper forms?',
        answer:
          'Most companies in the industry are still using paper-based systems. Our network is browser-based making it accessible and easy to use on a computer or mobile device.'
      },
      {
        question: 'Can my small business afford a trace system?',
        answer:
          'TraceWorks.IO fee is a per trace document event fee rather than a high subscription model. This fee will be kept very reasonable reducing your risk as you comply with the new federal regulations pertaining to trace.'
      }
    ]);
  }
}
