import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-features-icons-col',
  templateUrl: './features-icons-col.component.html',
  styleUrls: ['./features-icons-col.component.scss']
})
export class FeaturesIconsColComponent implements OnInit {
  elements = [
    {
      icon: 'image',
      title: 'What is Trace',
      body:
        'Traceability is the ability to verify the history, location, or application of an item within a supply chain by means of documented recorded identification. Within a trace system, transaction metadata can also be retrieved.'
    },
    {
      icon: 'sliders',
      title: 'TraceWorks.IO',
      body:
        'TraceWorks.IO is first and foremost a trace company using modern software best practices to create supply chain traceability networks to solve modern traceability challenges for food in general and beginning with the seafood industry. '
    },
    {
      icon: 'target',
      title: 'Compliance',
      body:
        'On January 01, 2018 the FDA Food Safety Modernization Act (FSMA) was implemented to address current industry issues by requiring supply-chain-applied control for certain raw materials and other ingredients. TraceWorks.IO is designed to meet this need.'
    }
  ];

  constructor() {}

  ngOnInit() {}
}
