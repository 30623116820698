import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'dc-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.scss']
})
export class FormContactComponent implements OnInit {
  contactForm: FormGroup;
  showForm: boolean = true;

  constructor(  private http: HttpClient,
                private snackbar: MatSnackBar  ) {}

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'message': new FormControl(null, [Validators.required])
    })
  }

  onSubmit() {
    if (!this.contactForm.valid) {
      this.openSnackBar('Please fix your form. See errors in red.')
    } else {
      this.sendMessage();
      this.showForm = false;
      setTimeout(() => {
        this.contactForm.reset();
        this.showForm = true;
      }, 10);
    }
  }
  sendMessage() {
    // using php
    const phpdata = {
      functionname: 'sendmail',
      arguments: [
        this.contactForm.get('name').value,
        this.contactForm.get('email').value,
        this.contactForm.get('message').value
      ]
    };
    console.log('phpdata', phpdata);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.http.post<any>('secure_email.php', phpdata, httpOptions)
      .subscribe((res) => {
        console.log('sendMessage response: ', res);
      }, error => {
        console.log('sendMessage error: ', error);
      });
  }

  openSnackBar(message: string) {
    console.log (message);
    this.snackbar.open(message, 'OK', {
      duration: 4000,
    });
  }
}
